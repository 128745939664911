<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Keywords'" :title="'Keywords'">
      <div class="list__buttons">
        <div class="list__search">
          <InputSearch
            :search="handleSearch"
            :id="'search'"
            :label="'search'"
            :placeholder="'search...'"
            :setValue="filterData"
          />
        </div>
        <MainButton v-if="isAdmin" class="big" @click.native="handleCreateArray()"
          >Create Multi</MainButton
        >
        <MainButton v-if="isAdmin" @click.native="handleCreate()">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="keywordsList.length">
          <CustomTable
            v-if="keywordsList.length"
            :data="keywordsList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="handleDelete"
            :handle-edit="handleEdit"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :order="orderSort"
            :name="'keywords'"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="keywords.lastPage"
              :page="page"
              :dataList="keywords"
              :urlList="'KEYWORDS_LIST'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Keywords found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Keywords - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Keywords {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import templatesApi from "~/api/templates";
import { getStoredUserInfo } from "~/helpers/userAuth";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";

export default {
  name: "KeywordsList",
  metaInfo: {
    title: "Keywords List",
  },
  data() {
    return {
      isAdmin: false,
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "parent_id",
      tableOptions: {
        deletedKeys: ["null", "fieldStatus"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "category_name",
            to: "Category name",
          },
          {
            from: "keyword",
            to: "Keyword",
          },
          {
            from: "type",
            to: "Type",
          },
          {
            from: "childrenCount",
            to: "Children count",
          },
          {
            from: "keyPoints",
            to: "Key Points",
          },
          {
            from: "targetAudience",
            to: "Target Audience",
          },
          {
            from: "templateOverview",
            to: "Template Overview",
          },
          {
            from: "previewRequirements",
            to: "Preview Requirements",
          },
          {
            from: "templatesCount",
            to: "Templates Count",
          },
        ],
        customOrder: [
          "id",
          "category_name",
          "keyword",
          "type",
          "childrenCount",
          "keyPoints",
          "targetAudience",
          "templateOverview",
          "previewRequirements",
          "fieldStatus",
          "templatesCount",
          "null",
        ],
      },
      filterData: {
        search: null,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputSearch,
  },
  mounted() {
    this.setQueryParams();
    // this.getList();

    if (getStoredUserInfo()) {
      const user = getStoredUserInfo();
      if (user.groups[0].id == 1 || user.groups[0].id == 2) {
        this.isAdmin = true;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "keywords", "keywordsList"]),
    page() {
      return +this.$route.params.page;
    },
    getQueryParameters() {
      const query = this.$route.fullPath.split("?")[1];
      if (query) {
        return `?${query}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.filterData.search != null) {
        const data = {};
        data.search = this.filterData.search;
        this.$store.commit("templates/setLoading", true);
        templatesApi
          .getKeywordsListFilter(`?page=${this.page}`, data)
          .then((res) => {
            this.$store.commit("templates/setError", {});
            this.$store.commit("templates/setValidationErrors", {});

            const keywords = res.data.data.items.data;

            for (let i = 0; keywords.length > i; i++) {
              if (keywords[i].parentId) {
                keywords[i].type = "child";
              } else {
                keywords[i].type = "parent";
              }

              if (keywords[i].category) {
                keywords[i].category_name = keywords[i].category[0]?.fullName.replace(
                  "Freebies / ",
                  "",
                );
              }

              keywords[i].keyPoints =
                keywords[i].keyPoints && keywords[i].keyPoints.length > 0 ? "yes" : "no";

              keywords[i].targetAudience =
                keywords[i].targetAudience && keywords[i].targetAudience.length > 0 ? "yes" : "no";

              keywords[i].templateOverview =
                keywords[i].templateOverview && keywords[i].templateOverview.length > 0
                  ? "yes"
                  : "no";

              keywords[i].previewRequirements =
                keywords[i].previewRequirements && keywords[i].previewRequirements.length > 0
                  ? "yes"
                  : "no";

              if (
                keywords[i].keyPoints == "yes" &&
                keywords[i].targetAudience == "yes" &&
                keywords[i].templateOverview == "yes" &&
                keywords[i].previewRequirements == "yes"
              ) {
                keywords[i].fieldStatus = "green";
              } else {
                keywords[i].fieldStatus = "red";
              }
            }

            this.$store.commit("templates/setKeywordsList", keywords);
            this.$store.commit("templates/setKeywords", res.data.data.items);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.$store.dispatch("templates/getKeywordsList", url);
      }
    },
    handleGetQueryParam(param) {
      if (this.$route.query[param] != undefined) {
        return this.$route.query[param];
      } else {
        return null;
      }
    },
    setQueryParams() {
      if (this.handleGetQueryParam("search")) {
        this.filterData.search = this.handleGetQueryParam("search");
      }

      this.getList();
    },
    handleSetQueryParams() {
      const filter = {};
      if (this.filterData.search != null) {
        filter.search = this.filterData.search;
      }
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.KEYWORDS_LIST.replace(":page", 1),
        });
      }
      this.$router.push({
        name: "KeywordsList",
        query: filter,
      });
    },
    handleSearch() {
      this.handleSetQueryParams();
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.KEYWORDS_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.KEYWORDS_CREATE,
      });
    },
    handleCreateArray() {
      this.$router.push({
        path: ROUTE.KEYWORDS_CREATE_ARRAY,
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}`;
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .deleteKeywords(url)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__buttons {
    display: flex;
  }
  &__search {
    min-width: 250px;
    margin-right: 20px;
  }
}
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}
/deep/.button-main {
  width: 200px;
}
/deep/.button-main.big {
  width: 200px;
  margin-right: 20px;
}
</style>
